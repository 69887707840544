import Axios from "axios";
import { toast } from "react-toastify";
import { ACCESS_TOKEN, API_URL, NEW_BACKEND_URL } from "../config/env";

const paramsSerializer = (params) => {
  let result = "";
  Object.keys(params).forEach((key) => {
    result += `${key}=${encodeURIComponent(params[key])}&`;
  });
  return result.substring(0, result.length - 1);
};

const authRequestInterceptor = (config) => {
  const authToken = localStorage.getItem("token");

  config.headers = config.headers ?? {};
  config.headers['Access-Token']=ACCESS_TOKEN
  if (authToken) {
    config.headers.authorization = `Bearer ${authToken}`;
  }
  config.headers.Accept = "application/json";
  return config;
};

const messagesNotToShow = [
  "Vendor, Restaurant or Invoice number are required to find duplicates",
];

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");

  if (!refreshToken) {
    throw new Error("No refresh token found. User must log in.");
  }

  try {
    const response = await instance.post("/api/user/token/refresh", {"refresh": refreshToken });


    const { access_token:access_token, refresh_token: newRefreshToken } = response?.data?.data;

    localStorage.setItem("token", access_token);
    if (newRefreshToken) {
      localStorage.setItem("refreshToken", newRefreshToken);
    }

    return access_token;
  } catch (error) {
    throw new Error("Refresh token expired or invalid.");
  }
};

export const errorInterceptor = async (error) => {
  if (error.response && error.response.status === 401) {
    const originalRequest = error.config;

    if (!originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newAccessToken = await refreshAccessToken();
          isRefreshing = false;
          onRefreshed(newAccessToken);
        } catch (refreshError) {
          isRefreshing = false;
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/login";
          return Promise.reject(refreshError);
        }
      }

      return new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          resolve(instance(originalRequest));
        });
      });
    }
  }

  if (!error.response) {
    if (error.code !== "ERR_CANCELED") {
      // toast.error("Something went wrong with the server");
    }
    return Promise.reject(error);
  }

  const message = error?.response?.data?.message || error?.message || "Something went wrong";

  if (!messagesNotToShow.includes(message)) {
    toast.error(message);
  }

  return Promise.reject(error);
};

export const axiosInstance = Axios.create({
  baseURL: API_URL,
});

export const instance = Axios.create({
  baseURL: NEW_BACKEND_URL,
});

instance.interceptors.response.use(null, errorInterceptor);
axiosInstance.interceptors.response.use(null, errorInterceptor);
axiosInstance.interceptors.request.use(authRequestInterceptor);
instance.interceptors.request.use(authRequestInterceptor);
axiosInstance.defaults.paramsSerializer = paramsSerializer;
