export const humanVerifiedFilters = [
  {
    key: "All",
    value: "all"
  },
  {
    key: "Yes",
    value: "true"
  },

  {
    key: "No",
    value: 'false'
  }
];
export const humanVerificationFilters = [
  {
    key: "All",
    value: "all"
  },
  {
    key: "Human Verified Required",
    value: "true"
  },
  {
    key: "Human Verified Not Required",
    value: "false"
  }
];

export const invoiceTypeFilters = [
  { key: "All", value: "all" },
  { key: "Normal Invoice", value: "Normal Invoice" },
  { key: "Liquor Invoice", value: "Liquor Invoice" },
  { key: "Summary Invoice", value: "Summary Invoice" }
];

export const invoiceDetectedFilters = [
  { key: "All", value: "all" },
  { key: "Detected", value: "detected" },
  { key: "Undetected", value: "undetected" }
];

export const rerunStatusFilters = [
  { key: "All", value: "all" },
  { key: "Rerun", value: "true" },
  { key: "Not Rerun", value: "false" }
];

export const autoAcceptedFilters = [
  { key: "All", value: "all" },
  { key: "True", value: "true" },
  { key: "False", value: "false" }
];

export const clickbaconFilters = [
  { key: "All", value: "all" },
  { key: "Pending", value: "pending" },
  { key: "Approved", value: "approved" },
  { key: "Rejected", value: "rejected" },
  { key: "Synced", value: "synced" },
  { key: "Manually Synced", value: "manually_synced" },
  { key: "Rereview Requested", value: "re_review_requested" }
];

export const sortFilters=[
    { key: "Ascending", value: "asc" },
    { key: "Descending", value: "desc" },
]