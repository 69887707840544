import { GaugeChart } from "@carbon/charts-react";
import Notes from "components/Comments/Notes";
import { Spinner } from "components/common/Spinner";
import { NavHeader } from "components/layout/NavHeader";
import { instance } from "lib/axios";
import { queryClient } from "lib/react-query";
import { PdfViewer } from "modules/InvoiceDetails/components/PdfViewer";
import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";
import { FileEarmarkPdf, JournalCheck, XCircle } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { globalStoreV2 } from "store/globalStoreV2";
import {
  useGetDocumentExtractionSetting,
  useGetVendorDetails,
  useGetVendorDocumentAnalytics,
  useGetVendorItemMasterAnalytics,
  useUpdateDocumentExtractionSetting
} from "./api";
import Header from "./components/Header";
import VendorDetailsTable from "./components/VendorDetails";
import { formatDate } from "utils/format";

export default function VendorDetails() {
  const { vendorID } = useParams();
  const { actual_vendor_name } = globalStoreV2();
  const { data, isLoading } = useGetVendorDetails(vendorID);
  // const {data:venorNotes,isLoading:loadingNotes}=useGetVendorNotes(vendorID)
  const tooltip = <Tooltip id="tooltip">View Vendor Notes</Tooltip>;
  const pdfTooltip = <Tooltip id="tooltip">View Vendor Pdfs</Tooltip>;
  const [showNotes, setShowNotes] = useState(false);
  const [showPdfs, setShowPdfs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfsData, setPdfsData] = useState();
  const getInvoicePdfs = async () => {
    setLoading(true);
    const body = {
      fetch_mode: "vendor_id",
      vendors: [vendorID]
    };
    const response = await instance.post("/api/vendor/pdf/", body);
    if (response?.status === 200) {
      // setPdfsData(response?.data?.data);
      setPdfsData(response?.data?.data?.[vendorID]);
      setLoading(false);
    } else {
      // setLoading(false)
    }
  };
  useEffect(() => {
    if (showPdfs) {
      getInvoicePdfs();
    }
  }, [showPdfs]);
  const { vendorID: vendor_id } = useParams();
  const [isAccordion1Open, setIsAccordion1Open] = useState(false);
  const [isAccordion2Open, setIsAccordion2Open] = useState(false);
  const [isAccordion3Open, setIsAccordion3Open] = useState(false);

  const { data: vendorAnalytics, isLoading: loadingAnalytics } =
    useGetVendorDocumentAnalytics({ vendor_id, toFetch: isAccordion1Open });
  const { data: extractionSettings, isLoading: loadingExtractionSettings } =
    useGetDocumentExtractionSetting({ vendor_id, toFetch: isAccordion2Open });
  const { mutate: updateSetting, isPending: updatingSetting } =
    useUpdateDocumentExtractionSetting();

  const handleSettingsChange = (key, value) => {
    updateSetting(
      { vendor_id: vendor_id, data: { ...extractionSettings, [key]: value } },
      {
        onSuccess: (data) => {
          toast.success(data?.message, {
            autoClose: 3000
          });
          queryClient.invalidateQueries({
            queryKey: ["vendor-document-extraction-settings"]
          });
          queryClient.invalidateQueries({
            queryKey: ["vendor-document-analytics"]
          });
        },
        onError: (data) => {
          toast.error(data?.message);
        }
      }
    );
  };

  const {
    data: vendorItemMasterAnalytics,
    isLoading: loadingVendorItemMasteranalytics
  } = useGetVendorItemMasterAnalytics({ vendor_id: vendorID, toFetch: isAccordion3Open });

  return (
    <>
      <NavHeader view="Vendor Details" />
      <ToastContainer autoClose={4000} />
      {isLoading && (
        <div
          className="d-flex justify-content-center h-15"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <Header
            text={`Vendor Details for ${data?.data?.vendor_name} `}
            archived={data?.data?.archive_status}
          >
            <OverlayTrigger placement="left" overlay={tooltip}>
              <Button
                variant="success"
                className="mx-2"
                onClick={() => setShowNotes(true)}
                disabled={vendorID === null}
              >
                <JournalCheck
                  height={20}
                  width={20}
                  fill="white"
                  className="cursor-pointer warning"
                />
              </Button>
            </OverlayTrigger>
            {showPdfs ? (
              <Button
                variant="success"
                className="mx-2"
                onClick={() => setShowPdfs(!showPdfs)}
              >
                <XCircle />
              </Button>
            ) : (
              <OverlayTrigger placement="left" overlay={pdfTooltip}>
                <Button
                  variant="success"
                  className="mx-2"
                  onClick={() => setShowPdfs(!showPdfs)}
                >
                  <FileEarmarkPdf
                    height={20}
                    width={20}
                    fill="white"
                    className="cursor-pointer warning"
                  />
                </Button>
              </OverlayTrigger>
            )}
          </Header>
          <Row style={{ overflow: "hidden", margin: 0 }}>
            <Col>
              <div className="px-5 d-flex mt-1 p-2 w-15 justify-content-center w-100">
                <Accordion
                  className="my-3 w-15 w-100"
                  activeKey={isAccordion1Open ? "0" : null} // Controls the accordion's state
                  onSelect={(eventKey) => {
                    const isOpening = eventKey === "0";
                    setIsAccordion1Open(isOpening);
                  }}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Document Extraction Efficiency
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="align-items-center gap-4 w-100">
                        <div
                          className="w-15  justify-content-center p-4  "
                          style={{ flexDirection: "column", display: "flex" }}
                        >
                          <div className="d-flex gap-4 justify-content-between w-100">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 5,
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                minWidth: "400px"
                              }}
                            >
                              <GaugeChart
                                className="border"
                                data={[
                                  {
                                    group: "value",
                                    value:
                                      vendorAnalytics?.data
                                        ?.metadata_extraction_efficiency
                                  }
                                ]}
                                options={{
                                  // title: 'Metadata Table Extraction Efficiency',
                                  resizable: false,
                                  width: "300px",
                                  height: "150px",
                                  gauge: {
                                    type: "semi",
                                    status: "danger"
                                  }
                                }}
                              />
                              <p style={{ marginLeft: -100 }} className="mt-2 ">
                                Metadata Table Extraction Efficiency
                              </p>
                            </div>
                            <div
                              className="w-15  justify-content-center p-4  "
                              style={{
                                flexDirection: "column",
                                display: "flex"
                              }}
                            >
                              <p style={{ fontWeight: 600 }}>
                                Total Documents Synced :-{" "}
                                {vendorAnalytics?.data?.total_synced_documents}{" "}
                              </p>
                              <p style={{ fontWeight: 600 }}>
                                {" "}
                                Analysis Perod :-{" "}
                                {
                                  formatDate(vendorAnalytics?.data?.start_date)
                                }{" "}
                                to{" "}
                                {
                                  formatDate(vendorAnalytics?.data?.end_date)
                                }
                              </p>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 5,
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                minWidth: "400px"
                              }}
                            >
                              <GaugeChart
                                data={[
                                  {
                                    group: "value",
                                    value:
                                      vendorAnalytics?.data
                                        ?.table_extraction_efficiency
                                  }
                                ]}
                                options={{
                                  // title: 'Table Extraction Efficiency',
                                  resizable: false,
                                  width: "300px",
                                  height: "150px",
                                  gauge: {
                                    type: "semi",
                                    status: "danger"
                                  }
                                }}
                              />
                              <p style={{ marginLeft: -100 }} className="mt-2 ">
                                Table Extraction Efficiency
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Accordion
                        className="my-3 w-15 w-100 "
                        activeKey={isAccordion2Open ? "0" : null} // Controls the accordion's state
                        onSelect={(eventKey) => {
                          const isOpening = eventKey === "0";
                          setIsAccordion2Open(isOpening);
                        }}
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Table Column Extraction Efficiencies Breakdown
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(3, 1fr)",
                                  gap: "5px",
                                  marginBottom: 5,
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 5,
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    minWidth: "00px"
                                  }}
                                >
                                  <GaugeChart
                                    data={[
                                      {
                                        group: "value",
                                        value:
                                          vendorAnalytics?.data
                                            ?.table_column_extraction_efficiencies_breakdown
                                            ?.category_column_extraction_efficiency
                                      }
                                    ]}
                                    options={{
                                      // title: 'Table Extraction Efficiency',
                                      resizable: false,
                                      width: "200px",
                                      height: "150px",

                                      gauge: {
                                        type: "semi",
                                        status: "danger"
                                      }
                                    }}
                                  />
                                  <p
                                    style={{ marginLeft: -15 }}
                                    className="mt-2 "
                                  >
                                    Category Column Extraction Efficiency{" "}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 5,
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    minWidth: "400px"
                                  }}
                                >
                                  <GaugeChart
                                    data={[
                                      {
                                        group: "value",
                                        value:
                                          vendorAnalytics?.data
                                            ?.table_column_extraction_efficiencies_breakdown
                                            ?.item_code_column_extraction_efficiency
                                      }
                                    ]}
                                    options={{
                                      // title: 'Table Extraction Efficiency',
                                      resizable: false,
                                      width: "200px",
                                      height: "150px",
                                      gauge: {
                                        type: "semi",
                                        status: "danger"
                                      }
                                    }}
                                  />
                                  <p
                                    style={{ marginLeft: -15 }}
                                    className="mt-2 "
                                  >
                                    Item Code Column Extraction Efficiency{" "}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 5,
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    minWidth: "400px"
                                  }}
                                >
                                  <GaugeChart
                                    data={[
                                      {
                                        group: "value",
                                        value:
                                          vendorAnalytics?.data
                                            ?.table_column_extraction_efficiencies_breakdown
                                            ?.item_description_column_extraction_efficiency
                                      }
                                    ]}
                                    options={{
                                      // title: 'Table Extraction Efficiency',
                                      resizable: false,
                                      width: "200px",
                                      height: "150px",
                                      gauge: {
                                        type: "semi",
                                        status: "danger"
                                      }
                                    }}
                                  />
                                  <p
                                    style={{ marginLeft: -15 }}
                                    className="mt-2 "
                                  >
                                    Item Description Column Extraction
                                    Efficiency{" "}
                                  </p>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 5,
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    minWidth: "400px"
                                  }}
                                >
                                  <GaugeChart
                                    data={[
                                      {
                                        group: "value",
                                        value:
                                          vendorAnalytics?.data
                                            ?.table_column_extraction_efficiencies_breakdown
                                            ?.quantity_column_extraction_efficiency
                                      }
                                    ]}
                                    options={{
                                      // title: 'Table Extraction Efficiency',
                                      resizable: false,
                                      width: "200px",
                                      height: "150px",
                                      gauge: {
                                        type: "semi",
                                        status: "danger"
                                      }
                                    }}
                                  />
                                  <p
                                    style={{ marginLeft: -15 }}
                                    className="mt-2 "
                                  >
                                    Quantity Column Extraction Efficiency{" "}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 5,
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    minWidth: "400px"
                                  }}
                                >
                                  <GaugeChart
                                    data={[
                                      {
                                        group: "value",
                                        value:
                                          vendorAnalytics?.data
                                            ?.table_column_extraction_efficiencies_breakdown
                                            ?.unit_price_column_extraction_efficiency
                                      }
                                    ]}
                                    options={{
                                      // title: 'Table Extraction Efficiency',
                                      resizable: false,
                                      width: "200px",
                                      height: "150px",
                                      gauge: {
                                        type: "semi",
                                        status: "danger"
                                      }
                                    }}
                                  />
                                  <p
                                    style={{ marginLeft: -15 }}
                                    className="mt-2 "
                                  >
                                    Unit Price Column Extraction Efficiency{" "}
                                  </p>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 5,
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    minWidth: "400px"
                                  }}
                                >
                                  <GaugeChart
                                    data={[
                                      {
                                        group: "value",
                                        value:
                                          vendorAnalytics?.data
                                            ?.table_column_extraction_efficiencies_breakdown
                                            ?.extended_price_column_extraction_efficiency
                                      }
                                    ]}
                                    options={{
                                      // title: 'Table Extraction Efficiency',
                                      resizable: false,
                                      width: "200px",
                                      height: "150px",
                                      gauge: {
                                        type: "semi",
                                        status: "danger"
                                      }
                                    }}
                                  />
                                  <p
                                    style={{ marginLeft: -15 }}
                                    className="mt-2 "
                                  >
                                    Extended Price Column Extraction Efficiency{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion
                        className="my-3 w-15 w-100"
                        activeKey={isAccordion3Open ? "0" : null} // Controls the accordion's state
                        onSelect={(eventKey) => {
                          const isOpening = eventKey === "0";
                          setIsAccordion3Open(isOpening);


                        }}
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Vendor Item Master Extraction Efficiency
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>

                              <div style={{ fontWeight: 600, marginTop: 20, marginBottom: 20 }}>
                                <p><span>Analysis Period :- </span> <span>{formatDate(vendorItemMasterAnalytics?.start_date)} to  {formatDate(vendorItemMasterAnalytics?.end_date)}</span></p>
                                <p><span>Total Human Verified Items :- </span> {vendorItemMasterAnalytics?.total_human_verified_items}</p>
                                <p><span>Total Category Verified Items:- </span> {vendorItemMasterAnalytics?.total_category_verified_items}</p>
                                
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(3, 1fr)",
                                  gap: "5px",
                                  marginBottom: 5,
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                  width: "100%"
                                }}
                              >
                                {vendorItemMasterAnalytics?.required_item_master_columns?.map(
                                  (col, index) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignContent: "center",

                                          alignItems: "center"
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 5,
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            minWidth: "400px"
                                          }}
                                        >
                                          <GaugeChart
                                            data={[
                                              {
                                                group: "value",
                                                value:
                                                  vendorItemMasterAnalytics
                                                    ?.item_master_extraction_efficiencies[
                                                  col
                                                  ]
                                              }
                                            ]}
                                            options={{
                                              resizable: false,
                                              width: "200px",
                                              height: "150px",
                                              gauge: {
                                                type: "semi",
                                                status: "danger"
                                              }
                                            }}
                                          />
                                          <p
                                            style={{ marginLeft: 0, textTransform: "capitalize" }}
                                            className="mt-2  capitalize"

                                          >
                                            {col?.split("_")?.join(" ")} Column Extraction
                                            Efficiency{" "}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <div
                        className="mt-2   p-2"
                        style={{
                          borderTop: "1px solid 	#E5E4E2",
                          display: "flex",
                          flexDirection: "column",
                          alignContent: "start",
                          justifyContent: "start",
                          alignItems: "start"
                        }}
                      >
                        <p style={{ fontWeight: 600 }}>
                          Document Efficiency Extraction Settings
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "20px",
                            justifyContent: "center"
                          }}
                        >
                          <div>
                            <p>Invoice Date Required</p>
                            <Form.Switch
                              checked={
                                extractionSettings?.invoice_date_required
                              }
                              onChange={(e) =>
                                handleSettingsChange(
                                  "invoice_date_required",
                                  e.target.checked
                                )
                              }
                            />
                          </div>

                          <div>
                            <p>Invoice Due Date Required</p>
                            <Form.Switch
                              checked={
                                extractionSettings?.invoice_due_date_required
                              }
                              onChange={(e) =>
                                handleSettingsChange(
                                  "invoice_due_date_required",
                                  e.target.checked
                                )
                              }
                            />
                          </div>

                          <div>
                            <p>Invoice Number Required</p>
                            <Form.Switch
                              checked={
                                extractionSettings?.invoice_number_required
                              }
                              onChange={(e) =>
                                handleSettingsChange(
                                  "invoice_number_required",
                                  e.target.checked
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <VendorDetailsTable
                data={data}
              // handleSaveVendorDetails={handleSaveVendorDetails}
              />
            </Col>
            {showPdfs && !loading && (
              <Col className="pt-5">
                <PdfViewer
                  pdfUrls={pdfsData}
                  isLoading={loading}
                  multiple={true}
                />
                {!pdfsData && <p>No Pdfs Available</p>}
              </Col>
            )}
          </Row>
          <Notes
            show={showNotes}
            setShow={setShowNotes}
            name="Vendor Notes"
            // data={venorNotes}
            vendorId={vendorID}
          />
        </>
      )}
    </>
  );
}
